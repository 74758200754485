<template>
  <div class="login-page">
    <Modal shown unclosable>
      <h2 slot="header">{{ $t("main.dearPartners") }}</h2>
      <section slot="body" class="showstopper">
        <h4 class="mb-2">
          {{ $t("main.textConditions") }}
        </h4>
        <p>
          {{ $t("main.textConditions1") }}
        </p>
        <p>
          {{ $t("main.textConditions2") }}
        </p>
        <p>
          {{ $t("main.textConditions3") }}
        </p>
      </section>
      <form slot="footer" @submit.prevent="submitForm()">
        <div class="row py-1">
          <div class="col-12">
            <Checkbox :val.sync="offer_accepted">
              {{ $t("common.iAgree") }}
              <a target="_blank" href="https://utraff.com/static/pdf/offer.pdf">
                {{ $t("main.contractOffer") }}
              </a>
            </Checkbox>
          </div>
        </div>
        <div class="row py-3">
          <div class="col-12">
            <div class="d-flex justify-content-center">
              <button
                type="submit"
                :disabled="!offer_accepted"
                class="form-btn"
              >
                {{ $t('common.continue') }}
              </button>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  </div>
</template>

<script>
import { Notification } from 'element-ui';
import Modal from '@/components/Modal.vue';
import Checkbox from '@/components/decorative/Checkbox.vue';

export default {
  name: 'ShowStopper',
  components: {
    Modal,
    Checkbox,
  },
  data() {
    return {
      rules_accepted: false,
      offer_accepted: false,
    };
  },
  methods: {
    submitForm() {
      // eslint-disable-next-line
      const { offer_accepted } = this;

      this.$store
        .dispatch('authDataRequest', {
          action: 'me',
          method: 'PATCH',
          // eslint-disable-next-line
          data: { recent_rules_accepted: offer_accepted },
        })
        .then(
          (resp) => {
            this.$store.commit(
              'setRecentRulesAccepted',
              resp.recent_rules_accepted,
            );
            this.$router.push('dashboard');
          },
          () => {
            Notification.error({
              title: this.$t('notification.error'),
              message: this.$t('notification.unknownError'),
            });
          },
        );
    },
  },
};
</script>
<style lang="scss">
.showstopper {
  p + p {
    margin-top: 8px;
  }
}
</style>
